s<template>
  <div>
    <section class="dashboardFilterHeader">
      <v-btn
        color="dark"
        text
        small
        @click="redirectToParent"
      >
        <v-icon> mdi-arrow-left </v-icon>
      </v-btn>
      <header-filters
        :filter-names="['calendar_type']"
        @onChange="onFiltersChange"
      />
      <div class="dateFilterCard">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          offset-x
          left
          :nudge-right="126"
          max-width="290px"
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formattedDate"
              v-bind="attrs"
              label=""
              readonly
              append-icon="mdi-calendar"
              hide-details
              v-on="on"
            />
          </template>
          <v-date-picker
            v-model="date"
            @input="menu = false"
          />
        </v-menu>
      </div>
    </section>
    <v-container
      fluid
      class="chargingDashboardContent"
    >
      <v-row>
        <v-col cols="12">
          <div class="customDataTable">
            <v-data-table
              :headers="headers"
              :items="sessions"
              class="elevation-1"
              dense
              hide-default-footer
              items-per-page="20"
            >
              <template v-slot:i*tem.location="{ item }">
                <div>
                  <div>{{ item.location }}</div>
                  <small class="text--secondary">{{ item.address }}</small>
                </div>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  small
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:item.status="{ item }">
                <v-chip
                  :class="item.status === 'Active'
                    ? 'status-active'
                    : 'status-inactive'
                  "
                  dark
                  small
                >
                  {{ item.status }}
                </v-chip>
              </template>
            </v-data-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
  },
  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 10), // Initialize with today's date
      headers: [
        { text: "Charger ID", value: "id", class: "col-charger-id" },
        { text: "Tenant Name", value: "tenantName", class: "col-tenant-name" },
        { text: "Location", value: "location", class: "col-location" },
        {
          text: "Station Type",
          value: "stationType",
          class: "col-station-type",
        },
        {
          text: "Charging Sessions",
          value: "chargingSessions",
          class: "col-charging-sessions",
        },
        { text: "Revenue", value: "revenue", class: "col-revenue" },
        { text: "Connectors", value: "connectors", class: "col-connectors" },
        { text: "Access", value: "access", class: "col-access" },
        { text: "Status", value: "status", class: "col-status" },
        { text: "Actions", value: "actions", class: "col-actions" },
      ],
      sessions: [
        {
          id: "12",
          tenantName: "West Inc",
          location: "376 Greenwich Street, New York",
          stationType: "AC",
          chargingSessions: "32",
          revenue: "1408",
          connectors: "2",
          access: "Public",
          status: "Active",
        },
        {
          id: "13",
          tenantName: "West Inc",
          location: "376 Greenwich Street, New York",
          stationType: "DC",
          chargingSessions: "12",
          revenue: "396",
          connectors: "2",
          access: "Public",
          status: "Active",
        },
        {
          id: "22",
          tenantName: "Hudson Management",
          location: "20 Hudson Yards, New York",
          stationType: "AC",
          chargingSessions: "45",
          revenue: "1188",
          connectors: "2",
          access: "Public",
          status: "Active",
        },
        {
          id: "23",
          tenantName: "Hudson Management",
          location: "20 Hudson Yards, New York",
          stationType: "AC",
          chargingSessions: "32",
          revenue: "704",
          connectors: "2",
          access: "Public",
          status: "Active",
        },
      ],
      options: {
        type: "week",
        mode: "stack",
      },
      stats: {
        faults: 7,
        pending: 1,
        cost: "$1,800",
      },
      // types: ["month", "week", "day", "4day"],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      // weekdays: [
      //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      //   { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      // ],
      value: "",
      events: [],
      //   colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],

      // show event
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
    formattedDate() {
      const options = { day: "2-digit", month: "short", year: "numeric" };
      return new Date(this.date).toLocaleDateString("en-GB", options);
    },
  },
  //   watch: {
  //     "options.calendar_type": {
  //       handler: function () {
  //       },
  //     },
  //   },

  methods: {
    onFiltersChange(v) {
      this.options = v;
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      await this.$store.dispatch("csmsBookings/list", this.options);
      await this.getEvents(val);
      ("");
    }, 100),
    redirectToParent() {
      this.$router.push("/charging-dashboard");
    },
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.status-cell
  display: flex
  align-items: center


.status-dot
  width: 0.875rem
  height: 0.875rem
  border-radius: 50%

.dot-green
  background-color: #23BDAA

.dot-red
  background-color: #FA896B

.dateFilterCard
  display: flex
  margin-left: auto
  width:35%
  .v-input
    padding-top:0 !important
    margin-bottom: 0 !important
    margin-top:0 !important
    :deep .v-input__control
      .v-input__slot
        background: #F6F6F6
        border-radius: 6px
        padding: 0 0.8rem
        &:before
          display: none
        &:after
          display: none
        .v-text-field__slot
          input
            font-size: 0.875rem
            color: #22272E
        .v-input__append-inner
          .v-input__icon
            color: $primaryColor
            justify-content: flex-end
            i
              color: $primaryColor
              font-size: 1rem

.dashboardFilterHeader
  display: flex
  align-items: center
  padding: 6px 12px 0
  .headerBarWrap
    align-items: center
    padding: 0px 0

  :deep .filterBtn
    display: none !important

  .dateFilterCard
    width: 10.5%
    .v-input
      :deep .v-input__control
        .v-input__slot
          background: #f6f9fc !important
          border-radius: 6px
          padding: 0 0.8rem
          border: 1px solid #ddd

.drawerOpen .dashboardFilterHeader .dateFilterCard
  width: 12%

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 48%


.customDataTable
  padding: 12px
  .smallTableText
    color: #8E8E8E
    font-weight: 300
    font-size: 0.675rem
  :deep .v-data-table--dense > .v-data-table__wrapper > table > thead > tr > th
    height: 3rem
    padding: 0 12px
    font-size: 0.875rem !important
  :deep .v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td
    height: 3rem
    padding: 0 12px
    font-size: 0.875rem !important

.status-active
  background: #D6F5D6 !important
  color: #387838

.status-inactive
  background-color: #fa896b
  color: white
</style>